<template>
  <div>
    <search-industry-type @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('industry_types/new')"
        >新增</a-button>

        <update-effective
          v-if="isShowMenu('industry_types/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增模态框 -->
    <new-industry-type
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-industry-type
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findIndustryTypes } from '@/api/industry_type'
import Pagination from '@/components/Pagination'
import SearchIndustryType from '@/views/industry_types/Search'

export default {
  name: 'IndustryTypeList',
  components: {
    Pagination,
    SearchIndustryType,
    EditIndustryType: () => import('@/views/industry_types/Edit'),
    NewIndustryType: () => import('@/views/industry_types/New'),
    UpdateEffective: () => import('@/views/industry_types/UpdateEffective')
  },
  data() {
    return {
      data: [],
      columns: [
        {
          title: '行业',
          dataIndex: 'name',
          width: 200,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '状态',
          dataIndex: 'effective',
          width: 100
        },
        {
          title: '说明',
          ellipsis: true,
          dataIndex: 'description',
          width: 250
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      loading: true,
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowNewModal: false, // 是否显示新增表单弹窗
      editingId: 0, // 正在编辑的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: { effective: '1' }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isHasEditPermission() {
      return hasPermission('industry_types/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findIndustryTypes(Object.assign({}, this.query)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

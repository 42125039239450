import request from '@/utils/request'

// 查询行业类型列表数据
export function findIndustryTypes(params) {
  return request({
    url: `/industry_types`,
    method: 'get',
    params: params
  })
}

// 查询单个行业类型表单值
export function findIndustryTypeForm(id) {
  return request({
    url: `/industry_types/${id}/form`,
    method: 'get'
  })
}

// 更新行业类型数据
export function updateIndustryType(id, data) {
  return request({
    url: `/industry_types/${id}`,
    method: 'put',
    data
  })
}

// 创建行业类型数据
export function createIndustryType(data) {
  return request({
    url: `/industry_types`,
    method: 'post',
    data
  })
}

// 更新行业有效性
export function batchUpdateIndustryTypesEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/industry_types/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询所有行业选项数据
export function findIndustryTypeOptions() {
  return request({
    url: `/industry_types/options`,
    method: 'get'
  })
}
